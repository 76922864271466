export const country_region = [
	{ value: "es_ES", label: "Španělsko - španělština" },
	{ value: "en_US", label: "USA - angličtina" },
	{ value: "es_AR", label: "Argentina - Spanish" },
	{ value: "en_AW", label: "Aruba - angličtina" },
	{ value: "en_AU", label: "Austrálie - angličtina" },
	{ value: "de_AT", label: "Rakousko - němčina" },
	{ value: "en_BS", label: "Bahamy - angličtina" },
	{ value: "en_BD", label: "Bangladéš - angličtina" },
	{ value: "en_BB", label: "Barbados - angličtina" },
	{ value: "nl_BE", label: "Belgie - holandština" },
	{ value: "fr_BE", label: "Belgie - francouzština" },
	{ value: "es_BO", label: "Bolívie - španělština" },
	{ value: "pt_BR", label: "Brazílie - portugalština" },
	{ value: "en_BG", label: "Bulharsko - angličtina" },
	{ value: "en_CA", label: "Kanada - angličtina" },
	{ value: "fr_CA", label: "Kanada - francouzština" },
	{ value: "es_CL", label: "Chile - španělština" },
	{ value: "zh_CN", label: "Čína - zjednodušená čínština" },
	{ value: "es_CO", label: "Kolumbie - španělština" },
	{ value: "es_CR", label: "Kostarika - španělština" },
	{ value: "cs_CZ", label: "Česká republika - čeština" },
	{ value: "da_DK", label: "Dánsko - dánština" },
	{ value: "es_DO", label: "Dominikánská republika - španělština" },
	{ value: "es_EC", label: "Ekvádor - španělština" },
	{ value: "en_EG", label: "Egypt - angličtina" },
	{ value: "es_SV", label: "Salvador - španělština" },
	{ value: "fi_FI", label: "Finsko - finština" },
	{ value: "fr_FR", label: "Francie - francouzština" },
	{ value: "de_DE", label: "Německo - němčina" },
	{ value: "el_GR", label: "Řecko - řečtina" },
	{ value: "en_GD", label: "Grenada - angličtina" },
	{ value: "es_GT", label: "Guatemala - španělština" },
	{ value: "es_HN", label: "Honduras - španělština" },
	{ value: "en_HK", label: "Hongkong - angličtina" },
	{ value: "zh_HK", label: "Hongkong - tradiční čínština" },
	{ value: "hu_HU", label: "Maďarsko - maďarština" },
	{ value: "en_IN", label: "Indie - angličtina" },
	{ value: "en_ID", label: "Indonésie - angličtina" },
	{ value: "in_ID", label: "Indonésie - Indonésan" },
	{ value: "en_IE", label: "Irsko - angličtina" },
	{ value: "en_IL", label: "Izrael - angličtina" },
	{ value: "it_IT", label: "Itálie - italština" },
	{ value: "en_JM", label: "Jamajka - angličtina" },
	{ value: "ja_JP", label: "Japonsko - japonština" },
	{ value: "ko_KR", label: "Korea - korejština" },
	{ value: "en_KW", label: "Kuvajt - angličtina" },
	{ value: "en_LB", label: "Libanon-angličtina" },
	{ value: "fr_LU", label: "Lucembursko - francouzština" },
	{ value: "en_MY", label: "Malajsie - angličtina" },
	{ value: "es_MX", label: "Mexiko - španělština" },
	{ value: "en_MA", label: "Maroko - angličtina" },
	{ value: "nl_NL", label: "Nizozemsko - holandština" },
	{ value: "en_NZ", label: "Nový Zéland - angličtina" },
	{ value: "es_NI", label: "Nikaragua - španělština" },
	{ value: "no_NO", label: "Norsko - norština" },
	{ value: "en_PK", label: "Pákistán - angličtina" },
	{ value: "es_PA", label: "Panama - španělština" },
	{ value: "es_PY", label: "Paraguay - španělština" },
	{ value: "es_PE", label: "Peru - španělština" },
	{ value: "en_PH", label: "Filipíny - angličtina" },
	{ value: "pl_PL", label: "Polsko - polština" },
	{ value: "pt_PT", label: "Portugalsko - portugalština" },
	{ value: "es_PR", label: "Portoriko - španělština" },
	{ value: "en_RO", label: "Rumunsko - angličtina" },
	{ value: "ru_RU", label: "Rusko - ruština" },
	{ value: "en_SA", label: "Saúdská Arábie - angličtina" },
	{ value: "en_SG", label: "Singapur - angličtina" },
	{ value: "en_LK", label: "Srí Lanka - angličtina" },
	{ value: "en_ZA", label: "Jihoafrická republika - angličtina" },
	{ value: "en_SI", label: "Slovinsko - angličtina" },
	{ value: "sv_SE", label: "Švédsko - švédština" },
	{ value: "fr_CH", label: "Švýcarsko - francouzština" },
	{ value: "de_CH", label: "Švýcarsko - němčina" },
	{ value: "zh_TW", label: "Tchaj-wan - tradiční čínština" },
	{ value: "en_TH", label: "Thajsko - angličtina" },
	{ value: "th_TH", label: "Thajsko - Thajské" },
	{ value: "en_TT", label: "Trinidad a Tobago - angličtina" },
	{ value: "tr_TR", label: "Turecko - turečtina" },
	{ value: "en_AE", label: "Spojené arabské emiráty - angličtina" },
	{ value: "en_GB", label: "Velká Británie - angličtina" },
	{ value: "es_UY", label: "Uruguay - španělština" },
	{ value: "es_VE", label: "Venezuela - španělština" },
	{ value: "en_VN", label: "Vietnam - angličtina" },

]

export const primaryUse = [
	{ value: "", label: "Vyberte jedno" },
	{ value: "002", label: "Osobní použití" },
	{ value: "003", label: "Domácí pracoviště" },
	{ value: "005", label: "Společnost s 9 nebo méně zaměstnanci" },
	{ value: "006", label: "Společnost s 10 nebo více zaměstnanci" },
]

export const salutation = [
	{ value: "", label: "Vyberte jedno" },
	{ value: "001", label: "Pan" },
	{ value: "002", label: "Paní" },
	{ value: "005", label: "Slečno" }
]

export const jobResponsibility = [
	{ value: "", label: "Vyberte jednu možnost" },
	{ value: "041", label: "Administrativní asistent" },
	{ value: "030", label: "Firemní odborník" },
	{ value: "035", label: "Odborník v oblasti využití výpočetní techniky v projektování a výrobě (CAD/CAM)" },
	{ value: "033", label: "Majitel podniku= výkonný ředitel" },
	{ value: "055", label: "Konzultant= systémový integrátor" },
	{ value: "034", label: "Vedoucí oddělení" },
	{ value: "046", label: "Pracovník technického oddělení" },
	{ value: "054", label: "Řídící pracovník nebo člen správní rady" },
	{ value: "036", label: "Vedoucí pracovník hospodářské správy" },
	{ value: "047", label: "Odborník v oblasti financí nebo účetnictví" },
	{ value: "031", label: "Vedoucí pracovník v oblasti financí (CFO)" },
	{ value: "037", label: "Odborník na grafiku" },
	{ value: "032", label: "IT Executive (CIO/CTO)" },
	{ value: "051", label: "Odborník v oblasti strategie informačních technologií" },
	{ value: "049", label: "Odborník na marketing" },
	{ value: "045", label: "Vedoucí oddělení nebo skupiny pro systémy pro správu informací či informačních technologií" },
	{ value: "044", label: "Odborník v oblasti správy informací a informačních technologií" },
	{ value: "042", label: "Vedoucí kanceláře" },
	{ value: "043", label: "Odborník v oblasti nákupů= zajišťování materiálu" },
	{ value: "050", label: "Odborník na prodej" },
	{ value: "040", label: "Servis= podpora= řešení problémů" },
	{ value: "048", label: "Vývojář softwaru" },
	{ value: "052", label: "Inspektor nebo ředitel školy" },
	{ value: "053", label: "Učitel" },
	{ value: "000", label: "Jiné" },
]

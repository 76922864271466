// libraies
import React from 'react';

// components

// services and hooks

// config

// styles and resources

// consts and independent functions

/** 
 * @category Components
 * @class
 * Component text input
 *
 * @param  {Object} props
 * @param  {String} props.label
 * @param  {Boolean} props.required
 * @param  {String} props.value
 * @param  {function} props.setValue
 * @param  {Boolean} props.errored
 * @return {Component}
 */
const TextInput = (props) => {
    const {label, required = false, value, setValue, errored = false} = props;
    return (
        <>
            <label>
                {label}{required && <span className="required"> * </span>}
            </label>
            <input type="text" value={value} className={errored ? 'invalid' : ''} onChange={(obj) => setValue(obj.target.value)}/>
        </>
    );
}

export default TextInput;
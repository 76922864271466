export function parseQueryParams(){
    const result = {};

    if (window.location.search) {
        const queryString = window.location.search.slice(1);
        const queryArray = queryString.split('&');

        queryArray.forEach((q) => {
            const [key, value] = q.split('=');
            result[key] = value;
        });
    }

    return result
}

export const country_region = [
	{ value: "es_ES", label: "Spania - spansk" },
	{ value: "en_US", label: "USA - engelsk" },
  { value: "es_AR", label: "Argentina - spansk" },
	{ value: "en_AW", label: "Aruba - engelsk" },
	{ value: "en_AU", label: "Australia - engelsk" },
	{ value: "de_AT", label: "Østerrike - tysk" },
	{ value: "en_BS", label: "Bahamas - engelsk" },
	{ value: "en_BD", label: "Bangladesh - engelsk" },
	{ value: "en_BB", label: "Barbados - engelsk" },
	{ value: "nl_BE", label: "Belgia - flamsk" },
	{ value: "fr_BE", label: "Belgia - fransk" },
	{ value: "es_BO", label: "Bolivia - spansk" },
	{ value: "pt_BR", label: "Brasil - portugisisk" },
	{ value: "en_BG", label: "Bulgaria - engelsk" },
	{ value: "en_CA", label: "Canada - engelsk" },
	{ value: "fr_CA", label: "Canada - fransk" },
	{ value: "es_CL", label: "Chile - spansk" },
	{ value: "zh_CN", label: "Kina - forenklet kinesisk" },
	{ value: "es_CO", label: "Colombia - spansk" },
	{ value: "es_CR", label: "Costa Rica - spansk" },
	{ value: "cs_CZ", label: "Tsjekkia - tsjekkisk" },
	{ value: "da_DK", label: "Danmark - dansk" },
	{ value: "es_DO", label: "Den dominikanske republikk - spansk" },
	{ value: "es_EC", label: "Ecuador - spansk" },
	{ value: "en_EG", label: "Egypt - engelsk" },
	{ value: "es_SV", label: "El Salvador - spansk" },
	{ value: "fi_FI", label: "Finland - finsk" },
	{ value: "fr_FR", label: "Frankrike - fransk" },
	{ value: "de_DE", label: "Tyskland - tysk" },
	{ value: "el_GR", label: "Hellas - gresk" },
	{ value: "en_GD", label: "Grenada - engelsk" },
	{ value: "es_GT", label: "Guatemala - spansk" },
	{ value: "es_HN", label: "Honduras - spansk" },
	{ value: "en_HK", label: "Hongkong - engelsk" },
	{ value: "zh_HK", label: "Hongkong - tradisjonell kinesisk" },
	{ value: "hu_HU", label: "Ungarn-ungarsk" },
	{ value: "en_IN", label: "India - engelsk" },
	{ value: "en_ID", label: "Indonesia - engelsk" },
	{ value: "in_ID", label: "Indonesia - Indonesisk" },
	{ value: "en_IE", label: "Irland - engelsk" },
	{ value: "en_IL", label: "Israel - engelsk" },
	{ value: "it_IT", label: "Italia - italiensk" },
	{ value: "en_JM", label: "Jamaica - engelsk" },
	{ value: "ja_JP", label: "Japan - japansk" },
	{ value: "ko_KR", label: "Sør-Korea - koreansk" },
	{ value: "en_KW", label: "Kuwait - engelsk" },
	{ value: "en_LB", label: "Libanon - engelsk" },
	{ value: "fr_LU", label: "Luxembourg - fransk" },
	{ value: "en_MY", label: "Malaysia - engelsk" },
	{ value: "es_MX", label: "Mexico - spansk" },
	{ value: "en_MA", label: "Marocco - engelsk" },
	{ value: "nl_NL", label: "Nederland - nederlandsk" },
	{ value: "en_NZ", label: "New Zealand - engelsk" },
	{ value: "es_NI", label: "Nicaragua - spansk" },
	{ value: "no_NO", label: "Norge - norsk" },
	{ value: "en_PK", label: "Pakistan - engelsk" },
	{ value: "es_PA", label: "Panama - spansk" },
	{ value: "es_PY", label: "Paraguay - spansk" },
	{ value: "es_PE", label: "Peru - spansk" },
	{ value: "en_PH", label: "Filippinene - engelsk" },
	{ value: "pl_PL", label: "Polen - polsk" },
	{ value: "pt_PT", label: "Portugal - portugisisk" },
	{ value: "es_PR", label: "Puerto Rico - spansk" },
	{ value: "en_RO", label: "Romania - engelsk" },
	{ value: "ru_RU", label: "Russland - russisk" },
	{ value: "en_SA", label: "Saudi-Arabia - engelsk" },
	{ value: "en_SG", label: "Singapore - engelsk" },
	{ value: "en_LK", label: "Sri Lanka - engelsk" },
	{ value: "en_ZA", label: "Sør-Afrika - engelsk" },
	{ value: "en_SI", label: "Slovenia - engelsk" },
	{ value: "sv_SE", label: "Sverige - svensk" },
	{ value: "fr_CH", label: "Sveits - fransk" },
	{ value: "de_CH", label: "Sveits - tysk" },
	{ value: "zh_TW", label: "Taiwan - tradisjonell kinesisk" },
	{ value: "en_TH", label: "Thailand - engelsk" },
	{ value: "th_TH", label: "Thailand - Thai" },
	{ value: "en_TT", label: "Trinidad og Tobago - engelsk" },
	{ value: "tr_TR", label: "Tyrkia - tyrkisk" },
	{ value: "en_AE", label: "De forente arabiske emirater - engelsk" },
	{ value: "en_GB", label: "Storbritannia - engelsk" },
	{ value: "es_UY", label: "Uruguay - spansk" },
	{ value: "es_VE", label: "Venezuela - spansk" },
	{ value: "en_VN", label: "Vietnam - engelsk" },

]

export const primaryUse = [
  { value: "", label: "Velg én" },
	{ value: "002", label: "Personlig bruk" },
	{ value: "003", label: "Hjemmebasert bedrift" },
	{ value: "005", label: "Bedrift med maksimalt 9 ansatte" },
	{ value: "006", label: "Bedrift med minst 10 ansatte" },
]

export const salutation = [
	{ value: "", label:"Select One" },
	{ value: "001", label:"Mr." },
	{ value: "002", label:"Mrs." },
	{ value: "005", label:"Miss" },
]

export const jobResponsibility = [
  { value: "", label: "Velg ett alternativ" },
	{ value: "041", label: "Administrasjonsassistent" },
	{ value: "030", label: "Forretningsmenneske" },
	{ value: "035", label: "DAK/DAP-medarbeider" },
	{ value: "033", label: "Bedriftseier / administrerende direktør" },
	{ value: "055", label: "Konsulent/systemintegrator" },
	{ value: "034", label: "Avdelingsleder" },
	{ value: "046", label: "Ingeniør/teknisk" },
	{ value: "054", label: "Leder/styremedlem" },
	{ value: "036", label: "Anleggssjef" },
	{ value: "047", label: "Økonomi-/regnskapsmedarbeider" },
	{ value: "031", label: "Økonomidirektør" },
	{ value: "037", label: "Grafikkmedarbeider" },
	{ value: "032", label: "IT-leder" },
	{ value: "051", label: "IT-strateg" },
	{ value: "049", label: "Markedsføringsmedarbeider" },
	{ value: "045", label: "MIS/IT-gruppe-/avdelingsleder" },
	{ value: "044", label: "MIS/IT-medarbeider" },
	{ value: "042", label: "Kontorsjef" },
	{ value: "043", label: "Innkjøper" },
	{ value: "050", label: "Salgsmedarbeider" },
	{ value: "040", label: "Service/brukerstøtte/helpdesk" },
	{ value: "048", label: "Programutvikler" },
	{ value: "052", label: "Inspektør/rektor" },
	{ value: "053", label: "Lærer" },
	{ value: "000", label: "Annet" },
]

import {useState, useEffect} from 'react';
import { checkLang } from './assets/i18n/lang';
import moment from 'moment';

import RegisterForm from './views/registerForm';
import FindForm from './views/findForm';
import ConfirmationForm from './views/confirmationForm';

function App() {
  const [formData, setFormData] = useState({
    country: "US",
    language: "EN",
    purchaseDay: moment().date(),
    purchaseMonth: moment().month() + 1,
    purchaseYear: moment().year(),
    b2cEmail: null,
    b2cMail: null,
    b2cPhone: null,
    b2cMobile: null,
    send: false
  });
  const [selectLang, setSelectLang] = useState({cc: 'US', lang: 'en'});

  const parseQueryParams = () => {
    const result = {};

    if (window.location.search) {
      const queryString = window.location.search.slice(1);
      const queryArray = queryString.split('&');

      queryArray.forEach((q) => {
        const [key, value] = q.split('=');
        result[key] = value;
      });
    }

    setSelectLang(checkLang({cc: result.cc, lang: result.lang}) ? {cc: result.cc, lang: result.lang} : {cc: 'US', lang: 'en'});
  };

  const resetData = () => {
    setFormData({
      country: selectLang.cc,
      language: selectLang.lang.toUpperCase(),
      purchaseDay: moment().date(),
      purchaseMonth: moment().month() + 1,
      purchaseYear: moment().year(),
    });
  }

  useEffect(() => {
    parseQueryParams();
  }, []);

  return (
    <div className="App">
      {!formData.productNumberName && !formData.send && <RegisterForm onRegister={(val) => setFormData((prevState) => ({...prevState, ...val}))} />}
      {formData.productNumberName && !formData.send && <FindForm data={formData} onSend={(data, val) => setFormData((prevState) => ({...prevState, ...data, send: val}))} />}
      {formData.send && <ConfirmationForm data={formData} onReset={resetData} />}
    </div>
  );
}

export default App;

export const SETTINGS = {
  server: {
    api: process.env.REACT_APP_API_URL,
    api_products: process.env.REACT_APP_API_PRODUCTS_URL,
    port: '443',
    search: 'search',
    fetch: 'fetchData',
    searchProduct: 'role/searchProductDetails',
    index: {
      role: process.env.REACT_APP_ROLE_INDEX,
      products: process.env.REACT_APP_PRODUCTS_INDEX,
    },
  },
  captcha: {
    sitekey: process.env.REACT_APP_CAPTCHA_KEY,
  },
  storeKeys: {},
};

export default SETTINGS;

// libraies
import React, {useState, useEffect} from 'react';
import { Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import validator from 'validator';
import examples from 'libphonenumber-js/mobile/examples'
import { isValidPhoneNumber, getExampleNumber, parsePhoneNumber } from 'libphonenumber-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'

// components
import TextInput from '../../components/textInput/TextInput';

// services and hooks
import { geti18nText, getMonths, geti18nLng, geti18nCC } from '../../assets/i18n/lang';

// config
import FORM_CONFIG from '../../config/formConfig.json';

// styles and resources

// consts and independent functions
import {getConstData} from '../../config/constants';
import {FetchService} from '../../services';
import Captcha from '../../components/captcha/Captcha';
import { getPrivacyUrl } from "../../tools/privacy";

const defaultValues = {
};

const isNil = (value) => value === null || value === undefined || value === NaN;

const mergeObjectValues = (values = {}, defaultValues = {}) => {
  return Object.keys(values).reduce((result, key) => {
    const _currentValue = values[key];
    const _defaultValue = defaultValues[key];

    if (isNil(_currentValue) && !isNil(_defaultValue)) {
      return {
        ...result,
        [key]: _defaultValue
      }
    }

    return {
      ...result,
      [key]: _currentValue
    }
  }, {})
}


/**
 * @category Views
 * @class
 * Main view
 *
 * @param  {Object} props
 * @return {View}
 */
const FindForm = (props) => {
  const {data, onSend} = props;
  const [formData, setFormData] = useState(mergeObjectValues(data, defaultValues));
  const [isFetching, setFetching] = useState(false);
  const [formConfig, setFormConfig] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [captchaValidated, setCaptchaValidated] = useState(false);

  const isVisibleField = (key) => formConfig.some((f) => f.id === key && !f.visible) ? {display: 'none'} : {};
  const isErroredField = (key) => formErrors[key] && formErrors[key].length > 0;
  const getFieldErrors = (key) => formErrors[key];

  const setFormValue = (key, value) => {
    const obj = {
      [key]: value,
      ...key === 'b2cMail' && {
        b2cMailLocale: !isNil(value) ? formData.language : undefined,
        b2cMailVersion: !isNil(value) ? '1' : undefined,
      },
      ...key === 'b2cEmail' && {
        b2cEmailLocale: !isNil(value) ? formData.language : undefined,
        b2cEmailVersion: !isNil(value) ? '1' : undefined,
      },
      ...key === 'b2cPhone' && {
        b2cPhoneLocale: !isNil(value) ? formData.language : undefined,
        b2cPhoneVersion: !isNil(value) ? '1' : undefined,
      },
      ...key === 'b2cMobile' && {
        b2cMobileLocale: !isNil(value) ? formData.language : undefined,
        b2cMobileVersion: !isNil(value) ? '1' : undefined,
      },
    };

    setFormData((prevState) => ({...prevState, ...obj}));
    setFormErrors((prevState) => prevState);
  }

  const sendForm = () => {
    let body = formData;
    const errors = {
      serialNumber: [],
      purchaseDay: [],
      purchaseYear: [],
      purchaseMonth: [],
      b2cEmail: [],
      firstName: [],
      lastName: [],
      company: [],
      email: [],
      phone: [],
      companyPhone: [],
      mobilePhone: [],
      mobilePhone: [],
      address1: [],
      companyAddress1: [],
      address2: [],
      companyAddress2: [],
      city: [],
      companyCity: [],
      zip: [],
      companyZip: []
    };

    let country = formData.isCompany ? formData.companyState : formData.state;


    ['serialNumber', 'purchaseDay', 'purchaseYear', 'purchaseMonth'].forEach(field => {
      if (!formData[field]) { errors[field]?.push(geti18nText('form_validation_required')); }
    });

    if (isNil(formData.b2cEmail)) {
      errors['b2cEmail']?.push(geti18nText('form_validation_required'));
    }

    if (!formData.firstName) { 
      errors['firstName']?.push(geti18nText('form_validation_required')); 
    }

    if (!formData.lastName) { 
      errors['lastName']?.push(geti18nText('form_validation_required')); 
    }

    if (formData.isCompany && !formData.company) {
      errors['company']?.push(geti18nText('form_validation_required')); 
    }

    if (!formData.email) {
      errors['email']?.push(geti18nText('form_validation_required')); 
    } else if (!validator.isEmail(formData.email)) {
      errors['email']?.push(geti18nText('form_validation_format_generic')); 
    }

    if (formData.companyPhone && !isValidPhoneNumber(formData.companyPhone, country)) {
      errors['companyPhone'].push(geti18nText('form_validation_format_phone').replace('%FORMAT%', getExampleNumber(country, examples).formatNational().replace(/[0-9]/gm,'X')).replace('%SAMPLE%',getExampleNumber(country, examples).formatNational()))
    } else if (!isNil(formData.b2cPhone) && formData.isCompany && !formData.companyPhone) {
      errors['companyPhone'].push(geti18nText('form_validation_required'))
    }  else if (formData.companyPhone && isValidPhoneNumber(formData.companyPhone, country)) {
      formData.companyPhone = parsePhoneNumber(formData.companyPhone, country).format('E.164')
    }

    if (formData.phone && !isValidPhoneNumber(formData.phone, country)) {
      errors['phone'].push(geti18nText('form_validation_format_phone').replace('%FORMAT%', getExampleNumber(country, examples).formatNational().replace(/[0-9]/gm,'X')).replace('%SAMPLE%',getExampleNumber(
        country, examples).formatNational()))
    } else if (!isNil(formData.b2cPhone) && !formData.isCompany && !formData.phone) {
      errors['phone'].push(geti18nText('form_validation_required'))
    } else if (formData.phone && isValidPhoneNumber(formData.phone, country)) {
      formData.phone = parsePhoneNumber(formData.phone, country).format('E.164')
    }

    if (formData.mobilePhone && !isValidPhoneNumber(formData.mobilePhone, country)) {
      errors['mobilePhone'].push(geti18nText('form_validation_format_phone').replace('%FORMAT%', getExampleNumber(country, examples).formatNational().replace(/[0-9]/gm,'X')).replace('%SAMPLE%',getExampleNumber(country, examples).formatNational()))
    } else if (!isNil(formData.b2cMobile) && !formData.mobilePhone) {
      errors['mobilePhone'].push(geti18nText('form_validation_required'))
    } else if (formData.mobilePhone && isValidPhoneNumber(formData.mobilePhone, country)) {
      setFormValue('mobilePhone', parsePhoneNumber(formData.mobilePhone, country).format('E.164'))
    }

    if (!isNil(formData.b2cMail) && formData.isCompany) {
      if (!formData.companyAddress1) {
        errors['companyAddress1'].push(geti18nText('form_validation_required'))
      }
      if (!formData.companyCity) {
        errors['companyCity'].push(geti18nText('form_validation_required'))
      }
      if (!formData.companyZip) {
        errors['companyZip'].push(geti18nText('form_validation_required'))
      }
    }

    if (!isNil(formData.b2cMail) && !formData.isCompany) {
      if (!formData.address1) {
        errors['address1'].push(geti18nText('form_validation_required'))
      }
      if (!formData.city) {
        errors['city'].push(geti18nText('form_validation_required'))
      }
      if (!formData.zip) {
        errors['zip'].push(geti18nText('form_validation_required'))
      }
    }

    setFormErrors(errors);
    if (Object.values(errors).filter(value => value.length > 0).length > 0) {
      return null;
    }
    body = {
      ...body,
      ...{
        b2cEmail: formData.b2cEmail,
        b2cEmailLocale: formData.language,
        b2cEmailVersion: "1",
      }
    }

    if (!isNil(formData.b2cMail)) {
      body = {
        ...body,
        ...{
          b2cMail: formData.b2cMail,
          b2cMailLocale: formData.language,
          b2cMailVersion: "1",
        }
      }
    }

    if (!isNil(formData.b2cMobile)) {
      body = {
        ...body,
        ...{
          b2cMobile: formData.b2cMobile,
          b2cMobileLocale: formData.language,
          b2cMobileVersion: "1",
        }
      }
    }

    if (!isNil(formData.b2cPhone)) {
      body = {
        ...body,
        ...{
          b2cPhone: formData.b2cPhone,
          b2cPhoneLocale: formData.language,
          b2cPhoneVersion: "1",
        }
      }
    }

    setFetching(true);
    FetchService.post(body, 'role').then((result) => {
      const {status} = result || {status: null};

      setFetching(false);
      if (status === 200 || status === 201) {
        onSend(body, true)
      }
    });
  };

  const renderMonthsName = () => {
    return (getMonths(geti18nLng()).map((item, index) => (<option key={`${item}_${index}`} value={index + 1}>{item}</option>)));
  };

  const renderMonthDays = () => {
    const days = moment(`${formData.purchaseMonth}/01/${formData.purchaseYear}`).daysInMonth();
    const options = Array.from({length: 31}, (v, i) => ({value: i + 1, label: i > 8 ? `${i + 1}` : `0${i + 1}`}));

    return (options.slice(0, days).map((item) => <option key={`${item.label}_month_day`} value={item.value}>{item.label}</option>))
  };

  const renderYears = () => {
    const currentYear = moment().year();
    const options = Array.from({length: 4}, (v, i) => currentYear - i);

    return (options.map((item) => <option key={`${item}_year`} value={item}>{item}</option>))
  }

  useEffect(() => {
    setFormConfig(FORM_CONFIG[geti18nLng()] || []);
  }, []);

  const getErrorMessage = (errorMessage) => {
    return <div style={{color: '#dc3545', fontWeight: 'bold'}}>{errorMessage}</div>
  }

  return (
    <div className="form-container">
      <div className="title-container"><h1>{geti18nText('title_register')}</h1></div>
      <div className="subtitle-container"><h2>{geti18nText('subtitle_register_producto').replace('%PRODUCT_NAME%', data.productName)}</h2></div>
      <div className="required-container"><span className="required">*</span>{geti18nText('requerido')}</div>
      <div className="block-container">
        <div className="input-container" style={isVisibleField('field_serial_number')}>
          <TextInput 
            label={geti18nText('field_serial_number')}
            required={true} 
            value={formData.serialNumber || ''} 
            setValue={(value) => setFormValue('serialNumber', value)}
            errored={isErroredField('serialNumber')}
          />
          {isErroredField('serialNumber') 
          && getErrorMessage(getFieldErrors('serialNumber').join('.'))}
        </div>
      </div>
      <div className="block-container">
        <div className="input-container" style={isVisibleField('field_buy_date')}>
          <label>{geti18nText('field_buy_date')}<span className="required"> * </span></label>
          <div className="group-selects-container">
            <Form.Select onChange={(v) => setFormValue('purchaseMonth', Number(v.target.value))} defaultValue={formData.purchaseMonth} className="select-months">
              {renderMonthsName()}
            </Form.Select>
            {isErroredField('purchaseMonth') 
            && getErrorMessage(getFieldErrors('purchaseMonth').join('.'))}
            <Form.Select onChange={(v) => setFormValue('purchaseDay', Number(v.target.value))} defaultValue={formData.purchaseDay} className="select-numbers">
              {renderMonthDays()}
            </Form.Select>
            {isErroredField('purchaseDay') 
            && getErrorMessage(getFieldErrors('purchaseDay').join('.'))}
            <Form.Select onChange={(v) => setFormValue('purchaseYear', Number(v.target.value))} defaultValue={formData.purchaseYear} className="select-numbers">
              {renderYears()}
            </Form.Select>
            {isErroredField('purchaseYear') 
            && getErrorMessage(getFieldErrors('purchaseYear').join('.'))}
          </div>
        </div>
      </div>
      <div className="block-container">
        <div className="input-container" style={isVisibleField('field_country_region2')}>
          <label>{geti18nText('field_country_region')}</label>
          {<Form.Select value={formData.purchaseLocation || geti18nCC()} onChange={(v) => setFormValue('purchaseLocation', v.target.value)}>
            {getConstData('country_region', geti18nLng())
              .map((item) => ({value: item.value.slice(item.value.indexOf('_') + 1).trim(), label: item.label.slice(0, item.label.indexOf('-'))}))
              .map((item, index) => (<option key={`${item.value}_${index}_purchaseLocation`} value={item.value}>{item.label}</option>))}
            </Form.Select>}
          {isErroredField('purchaseLocation') 
          && getErrorMessage(getFieldErrors('purchaseLocation').join('.'))}
        </div>
      </div>
      <div className="block-container">
        <div className="subtitle-container "><h3>{geti18nText('subtitle_personal_data')}</h3></div>
      </div>
      <div className="block-container">
        <div className="input-container pr20" style={isVisibleField('field_firstName')}>
          <TextInput 
            label={geti18nText('field_name')}
            required
            value={formData.firstName || '' }
            setValue={(value) => setFormValue('firstName', value)}
            errored={isErroredField('firstName')}
          />
          {isErroredField('firstName') 
          && getErrorMessage(getFieldErrors('firstName').join('.'))}
        </div>
        <div className="input-container pl20" style={isVisibleField('field_lastName')}>
          <TextInput 
            label={geti18nText('field_surname')}
            required
            value={formData.lastName || ''}
            setValue={(value) => setFormValue('lastName', value)}
            errored={isErroredField('lastName')}
          />
          {isErroredField('lastName') 
          && getErrorMessage(getFieldErrors('lastName').join('.'))}
        </div>
      </div>
      <div className="block-container">
        <div className="input-container pr20" style={isVisibleField('field_email')}>
          <TextInput 
            label={geti18nText('field_email')}
            type='email'
            required={true}
            value={formData.email || ''}
            setValue={(value) => setFormValue('email', value)}
            errored={isErroredField('email')}
          />
          {isErroredField('email') 
          && getErrorMessage(getFieldErrors('email').join('.'))}
        </div>
      </div>
      {
        data?.isCompany && (
          <div className="block-container">
            <div className="input-container" style={isVisibleField('field_company_name')}>
              <TextInput 
                label={geti18nText('field_company_name')}
                required={true}
                value={formData.company || ''}
                setValue={(value) => setFormValue('company', value)}
                errored={isErroredField('company')}
              />
              {isErroredField('company') 
              && getErrorMessage(getFieldErrors('company').join('.'))}
            </div>
          </div>
        )
      }
      <div className="block-container">
        <div className="input-container pr20" style={isVisibleField('field_address1')}>
          <TextInput 
            label={`${geti18nText('field_address')} 1`}
            value={(data.isCompany ? formData?.companyAddress1 : formData?.address1) || ''}
            setValue={(value) => setFormValue(data.isCompany ? 'companyAddress1' : 'address1', value)}
            errored={isErroredField(data.isCompany ? 'companyAddress1' : 'address1')}
          />
          {isErroredField(data.isCompany ? 'companyAddress1' : 'address1') 
          && getErrorMessage(getFieldErrors(data.isCompany ? 'companyAddress1' : 'address1').join('.'))}
        </div>
        <div className="input-container pl20" style={isVisibleField('field_address1')}>
          <TextInput 
            label={`${geti18nText('field_address')} 2`}
            value={(data.isCompany ? formData.companyAddress2 : formData.address2) || ''}
            setValue={(value) => setFormValue(data.isCompany ? 'companyAddress2' : 'address2', value)}
            errored={isErroredField(data.isCompany ? 'companyAddress2' : 'address2')}
          />
          {isErroredField(data.isCompany ? 'companyAddress2' : 'address2') 
          && getErrorMessage(getFieldErrors(data.isCompany ? 'companyAddress2' : 'address2').join('.'))}
        </div>
      </div>
      <div className="block-container">
        <div className="input-container" style={isVisibleField('field_country_region3')}>
          <label>{geti18nText('field_country_region')}</label>
          {<Form.Select value={   formData.state || geti18nCC()} onChange={(v) => setFormValue(data.isCompany ? 'companyState' : 'state', v.target.value)}>
            {getConstData('country_region', geti18nLng())
              .map((item) => ({value: item.value.slice(item.value.indexOf('_') + 1).trim(), label: item.label.slice(0, item.label.indexOf('-'))}))
              .map((item, index) => (<option key={`${item.value}_${index}_state`} value={item.value}>{item.label}</option>))}
            </Form.Select>}
            {isErroredField(data.isCompany ? 'companyState' : 'state') 
            && getErrorMessage(getFieldErrors(data.isCompany ? 'companyState' : 'state').join('.'))}
        </div>
      </div>
      <div className="block-container" style={isVisibleField('field_city')}>
        <div className="input-container pr20">
          <TextInput 
            label={geti18nText('city')}
            value={(data.isCompany ? formData.companyCity : formData.city) || ''}
            setValue={(value) => setFormValue(data.isCompany ? 'companyCity' : 'city', value)}
            errored={isErroredField(data.isCompany ? 'companyCity' : 'city')}
          />
          {isErroredField(data.isCompany ? 'companyCity' : 'city') 
          && getErrorMessage(getFieldErrors(data.isCompany ? 'companyCity' : 'city').join('.'))}
        </div>
        <div className="input-container pl20" style={isVisibleField('field_zip')}>
          <TextInput 
            label={geti18nText('field_postcode')}
            value={(data.isCompany ? formData.companyZip : formData.zip) || ''}
            setValue={(value) => setFormValue(data.isCompany ? 'companyZip' : 'zip', value)}
            errored={isErroredField(data.isCompany ? 'companyZip' : 'zip')}
          />
          {isErroredField(data.isCompany ? 'companyZip' : 'zip') 
          && getErrorMessage(getFieldErrors(data.isCompany ? 'companyZip' : 'zip').join('.'))}
        </div>
      </div>
      <div className="block-container">
        <div className="input-container pr20" style={isVisibleField('field_phone')}>
          <TextInput 
            label={geti18nText('field_phone')}
            value={(data.isCompany ? formData.companyPhone : formData.phone) || ''}
            setValue={(value) => setFormValue(data.isCompany ? 'companyPhone' :'phone', value)}
            errored={isErroredField(data.isCompany ? 'companyPhone' :'phone')}
          />
          {isErroredField(data.isCompany ? 'companyPhone' :'phone') 
          && getErrorMessage(getFieldErrors(data.isCompany ? 'companyPhone' :'phone').join('.'))}
        </div>
        <div className="input-container pl20" style={isVisibleField('field_mobilePhone')}>
          <TextInput 
            label={geti18nText('field_phone_mobile')}
            value={formData.mobilePhone || ''}
            setValue={(value) => setFormValue('mobilePhone', value)}
            errored={isErroredField('mobilePhone')}
          />
          {isErroredField('mobilePhone') 
          && getErrorMessage(getFieldErrors('mobilePhone').join('.'))}
        </div>
      </div>
      <hr />
      <p id="format-question">{geti18nText('subtitle_information_services')}</p>
      <div className="checkbox-container">
        <div className="checkbox-row" style={isVisibleField('radio_field_email')}>
            <label>{geti18nText('field_mail')}<span className="required"> * </span></label>
            <Form.Check isInvalid={isErroredField('b2cEmail')} type="radio" checked={!isNil(formData?.b2cEmail) && !!formData?.b2cEmail} className="checkradio" name="b2cEmail" id={`b2cEmail-yes`} label={geti18nText('yes')} onClick={() => setFormValue('b2cEmail', true)} />
            <Form.Check isInvalid={isErroredField('b2cEmail')} type="radio" checked={!isNil(formData?.b2cEmail) && !formData?.b2cEmail} className="checkradio" name="b2cEmail" id={`b2cEmail-no`} label={geti18nText('no')} onClick={() => setFormValue('b2cEmail', false)} />
            <a style={{color:'#dc3545', paddingTop:'3px', paddingRight: '15px'}} onClick={() => setFormValue('b2cEmail', undefined)}>
            <FontAwesomeIcon icon={faTrashCan} />
          </a>
            {isErroredField('b2cEmail') 
            && getErrorMessage(getFieldErrors('b2cEmail').join('.'))}
        </div>        
        <div className="checkbox-row" style={isVisibleField('radio_field_phone')}>
          <label>{geti18nText('field_phone')}</label>
          <Form.Check isInvalid={isErroredField('b2cPhone')} type="radio" checked={!isNil(formData?.b2cPhone) && !!formData?.b2cPhone} className="checkradio" name="b2cPhone" id={`b2cPhone-yes`} label={geti18nText('yes')} onClick={() => setFormValue('b2cPhone', true)} />
          <Form.Check isInvalid={isErroredField('b2cPhone')} type="radio" checked={!isNil(formData?.b2cPhone) && !formData?.b2cPhone} className="checkradio" name="b2cPhone" id={`b2cPhone-no`} label={geti18nText('no')} onClick={() => setFormValue('b2cPhone', false)} />
          <a style={{color:'#dc3545', paddingTop:'3px', paddingRight: '15px'}} onClick={() => setFormValue('b2cPhone', undefined)}>
            <FontAwesomeIcon icon={faTrashCan} />
          </a>
          {isErroredField('b2cPhone') 
          && getErrorMessage(getFieldErrors('b2cPhone').join('.'))}
        </div>
        <div className="checkbox-row" style={isVisibleField('radio_field_mail')}>
          <label>{geti18nText('field_address')}</label>
          <Form.Check isInvalid={isErroredField('b2cMail')} type="radio" checked={!isNil(formData?.b2cMail) && !!formData?.b2cMail} className="checkradio" name="b2cMail" id={`b2cMail-yes`} label={geti18nText('yes')} onClick={() => setFormValue('b2cMail', true)} />
          <Form.Check isInvalid={isErroredField('b2cMail')} type="radio" checked={!isNil(formData?.b2cMail) && !formData?.b2cMail} className="checkradio" name="b2cMail" id={`b2cMail-no`} label={geti18nText('no')} onClick={() => setFormValue('b2cMail', false)} />
          <a style={{color:'#dc3545', paddingTop:'3px', paddingRight: '15px'}} onClick={() => setFormValue('b2cMail', undefined)}>
            <FontAwesomeIcon icon={faTrashCan} />
          </a>
          {isErroredField('b2cMail') 
          && getErrorMessage(getFieldErrors('b2cMail').join('.'))}
        </div>
        <div className="checkbox-row" style={isVisibleField('radio_field_mobile_sms')}>
          <label>{geti18nText('field_mobile_sms')}</label>
          <Form.Check isInvalid={isErroredField('b2cMobile')} type="radio" checked={!isNil(formData?.b2cMobile) && !!formData?.b2cMobile} className="checkradio" name="b2cMobile" id={`b2cMobile-yes`} label={geti18nText('yes')} onClick={() => setFormValue('b2cMobile', true)} />
          <Form.Check isInvalid={isErroredField('b2cMobile')} type="radio" checked={!isNil(formData?.b2cMobile) && !formData?.b2cMobile} className="checkradio" name="b2cMobile" id={`b2cMobile-no`} label={geti18nText('no')} onClick={() => setFormValue('b2cMobile', false)} />
          <a style={{color:'#dc3545', paddingTop:'3px', paddingRight: '15px'}} onClick={() => setFormValue('b2cMobile', undefined)}>
            <FontAwesomeIcon icon={faTrashCan} />
          </a>
          {isErroredField('b2cMobile') 
          && getErrorMessage(getFieldErrors('b2cMobile').join('.'))}
        </div>
      </div>
      <hr />
      <a className="privacy-text" href={getPrivacyUrl()} target="_blank">{geti18nText('subtitle_lean_how')}</a>
      <div className="block-container block-container-justify-center">
        {!isFetching && <Captcha setValidated={setCaptchaValidated}/>}
      </div>
      <div className="block-container block-container-justify-center">  
        {!isFetching
          ? <input className="secondary-button" name="send-btn" disabled={!captchaValidated} type="button" onClick={sendForm} value={geti18nText('btn_send')} />
          : <div className="spin-container">
              <Spinner animation="border" size="lg" />
            </div>
        }
      </div>
  </div>
);
};

// class prop types

export default FindForm;

import { geti18nLng, geti18nCC } from '../assets/i18n/lang';
import { PRIVACY_URL } from '../config/privacy';

export const getPrivacyUrl = () => {
  const lang = geti18nLng();
  const cc = geti18nCC();
  const value = `${cc}-${lang}`.toLowerCase();

  return String(PRIVACY_URL).replace('%LANG%', value);
};

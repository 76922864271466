import { SETTINGS } from '../config/settings';
// import StorageService from './storageService';

const headers$ = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const $SERVER = SETTINGS.server;

const getApiEndPoint = (type, api = 'api') =>
  `${$SERVER[api]}/${['dev', 'pro', 'uat'].includes(type) ? $SERVER[type] : type}`;

function handleApiErrors(response) {
  if (!response.ok) {
    return {
      status: response.status,
      err: response.statusText,
    };
  }

  return response.json().then((json) => ({
    status: response.status,
    results: json,
  }));
}

function parseHttpErrors(err) {
  const errText = {
    status: 500,
    results: err,
  };

  return errText;
}

const FetchService = {
  async post(data, rType = 'fetch', rApi = 'api', multipart = false) {
    if (multipart) headers$['Content-Type'] = 'multipart/form-data';

    try {
      return fetch(getApiEndPoint(rType, rApi), {
        method: 'POST',
        headers: headers$,
        body: multipart ? data : JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 401) {
            // return Auth.currentSession().then((cognitoUser: any) => {
            //   const nToken = cognitoUser.accessToken.jwtToken;
            //   StorageService.save(SETTINGS.storeKeys.token, nToken);
            //   return FetchService.post(data, rType, multipart);
            // });
          }

          if (response.status === 500) return parseHttpErrors(response);

          return handleApiErrors(response);
        })
        .catch((error) => parseHttpErrors(error));
    } catch (err) {
      return null;
    }
  },
};

export default FetchService;

import { SETTINGS } from '../config/settings'

const $CAPTCHA = SETTINGS.captcha;


const CaptchaService = {
    getSiteKey() {
        return $CAPTCHA?.sitekey;
    }
}

export default CaptchaService;

const isObjectWithData = (o = {}) => typeof o === 'object' && Object.keys(o).length > 0;

export default {
  isObjectWithData,
};

export function clearObject(data) {
  if (Array.isArray(data)) {
    return data.map(clearObject);
  }

  if (typeof data === 'object') {
    return Object.keys(data).reduce((result, key) => {
      const rawValue = data?.[key];

      if (!rawValue) return result;

      const value = clearObject(rawValue);

      return {
        ...result,
        [key]: value,
      };
    }, {});
  }
  return data;
}

export const country_region = [
	{ value:"es_ES", label:"西班牙 - 西班牙语" },
	{ value:"en_US", label:"美国 - 英语" },
	{ value:"en_AA", label:"非洲 - 英语" },
	{ value:"fr_AA", label:"非洲 - 法语" },
	{ value:"es_AR", label:"阿根延 - 西班牙语" },
	{ value:"en_AU", label:"澳大利亚 - 英语" },
	{ value:"de_AT", label:"奥地利 - 德语" },
	{ value:"en_BD", label:"孟加拉国 - 英语" },
	{ value:"be_BY", label:"白俄罗斯 - 俄语" },
	{ value:"nl_BE", label:"比利时 - 荷兰语" },
	{ value:"fr_BE", label:"比利时 - 法语" },
	{ value:"es_BO", label:"玻利维亚 - 西班牙语" },
	{ value:"pt_BR", label:"巴西 - 葡萄牙语" },
	{ value:"bg_BG", label:"保加利亚 - 保加利亚语" },
	{ value:"en_BG", label:"保加利亚 - 英语" },
	{ value:"en_CA", label:"加拿大 - 英语" },
	{ value:"fr_CA", label:"加拿大 - 法语" },
	{ value:"en_CB", label:"加勒比海 - 英语" },
	{ value:"zh_CN", label:"中国 - 简体中文" },
	{ value:"es_NS", label:"中美洲 - 西班牙语" },
	{ value:"es_CL", label:"智利 - 西班牙语" },
	{ value:"es_CO", label:"哥伦比亚 - 西班牙语" },
	{ value:"hr_HR", label:"克罗地亚 - 克罗地亚语" },
	{ value:"el_GR", label:"希腊 - 希腊语" },
	{ value:"cs_CZ", label:"捷克共和国 - 捷克语" },
	{ value:"da_DK", label:"丹麦 - 丹麦语" },
	{ value:"es_EC", label:"厄瓜多尔 - 西班牙语" },
	{ value:"et_EE", label:"爱沙尼亚 - 爱沙尼亚语" },
	{ value:"fi_FI", label:"芬兰 - 芬兰语" },
	{ value:"fr_FR", label:"法国 - 法语" },
	{ value:"de_DE", label:"德国 - 德语" },
	{ value:"en_HK", label:"中国香港特别行政区 - 英语" },
	{ value:"zh_HK", label:"中国香港特别行政区 - 繁体中文" },
	{ value:"hu_HU", label:"匈牙利 - 匈牙利语" },
	{ value:"en_IN", label:"印度 - 英语" },
	{ value:"en_ID", label:"印度尼西亚 - 英语" },
	{ value:"in_ID", label:"印度尼西亚 - 印尼语" },
	{ value:"en_IE", label:"爱尔兰 - 英语" },
	{ value:"he_IL", label:"以色列 - 希伯来语" },
	{ value:"it_IT", label:"意大利 - 意大利语" },
	{ value:"ja_JP", label:"日本 - 日语" },
	{ value:"ru_KZ", label:"哈萨克斯坦 - 俄语" },
	{ value:"ko_KR", label:"朝鲜/韩国 - 朝鲜语" },
	{ value:"lv_LV", label:"拉脱维亚 - 拉脱维亚语" },
	{ value:"lt_LT", label:"立陶宛 - 立陶宛语" },
	{ value:"en_LB", label:"黎巴嫩 - 英文" },
	{ value:"fr_LU", label:"卢森堡 - 法语" },
	{ value:"en_PK", label:"巴基斯坦 - 英语" },
	{ value:"en_MY", label:"马来西亚 - 英语" },
	{ value:"es_MX", label:"墨西哥 - 西班牙语" },
	{ value:"en_ME", label:"沙特阿拉伯 - 英语" },
	{ value:"nl_NL", label:"荷兰 - 荷兰语" },
	{ value:"en_NZ", label:"新西兰 - 英语" },
	{ value:"no_NO", label:"挪威 - 挪威语" },
	{ value:"es_PY", label:"巴拉圭 - 西班牙语" },
	{ value:"es_PE", label:"秘鲁 - 西班牙语" },
	{ value:"en_PH", label:"菲律宾 - 英语" },
	{ value:"pl_PL", label:"波兰 - 波兰语" },
	{ value:"pt_PT", label:"葡萄牙 - 葡萄牙语" },
	{ value:"es_PR", label:"波多黎各 - 西班牙语" },
	{ value:"en_RO", label:"罗马尼亚 - 英语" },
	{ value:"ru_RU", label:"俄罗斯 - 俄语" },
	{ value:"sr_YU", label:"塞尔维亚 - 塞尔维亚语" },
	{ value:"en_SG", label:"新加坡 - 英语" },
	{ value:"en_LK", label:"斯里兰卡 - 英语" },
	{ value:"sk_SK", label:"斯洛伐克共和国 - 斯洛伐克语" },
	{ value:"en_SI", label:"斯洛文尼亚 - 英语" },
	{ value:"en_ZA", label:"南非 - 英语" },
	{ value:"sv_SE", label:"瑞典 - 瑞典语" },
	{ value:"fr_CH", label:"瑞士 - 法语" },
	{ value:"de_CH", label:"瑞士 - 德语" },
	{ value:"zh_TW", label:"中国台湾地区 - 繁体中文" },
	{ value:"en_TH", label:"泰国 - 英语" },
	{ value:"th_TH", label:"泰国 - 泰语" },
	{ value:"tr_TR", label:"土尔其 - 土尔其语" },
	{ value:"uk_UA", label:"乌克兰 - 俄语" },
	{ value:"en_AE", label:"阿拉伯联合酋长国 - 英语" },
	{ value:"en_GB", label:"英国 - 英语" },
	{ value:"es_UY", label:"乌拉圭 - 西班牙语" },
	{ value:"es_VE", label:"委内瑞拉 - 西班牙语" },
	{ value:"en_VN", label:"越南 - 英语" }
]

export const primaryUse = [
	{ value: "", label:"请选择" },
	{ value: "002", label:"个人使用" },
	{ value: "003", label:"家庭办公用" },
	{ value: "005", label:"雇员为1-9人的公司使用" },
	{ value: "006", label:"雇员为10人以上的公司使用" }
]

export const salutation = [
	{ value: "", label:"选择一个" },
	{ value: "001", label:"先生" },
	{ value: "002", label:"女士" },
	{ value: "003", label:"小姐" }
]

export const lifestylesandhobbies = [
	{ value: "", label:"选择一个" },
	{ value: "003", label:"摄影" },
	{ value: "010", label:"旅行" },
	{ value: "005", label:"家居" },
	{ value: "011", label:"教育" },
	{ value: "006", label:"聚会" },
	{ value: "012", label:"网络游戏" },
	{ value: "007", label:"住宅美化" },
	{ value: "013", label:"音乐" },
	{ value: "008", label:"运动" },
	{ value: "014", label:"电影" },
	{ value: "009", label:"宠物" },
	{ value: "015", label:"志愿工作" }
]

export const occupation = [
	{ value: "", label:"请选择" },
	{ value: "11", label:"管理(主任,经理等)" },
	{ value: "13", label:"财务会计" },
	{ value: "15", label:"计算机技术" },
	{ value: "17", label:"建築工程" },
	{ value: "21", label:"社会服务" },
	{ value: "23", label:"法务" },
	{ value: "25", label:"教师" },
	{ value: "27", label:"美工绘图" },
	{ value: "29", label:"医师" },
	{ value: "41", label:"业务销售" },
	{ value: "43", label:"一般行政" },
	{ value: "45", label:"农林牧" },
	{ value: "501", label:"家庭主妇" },
	{ value: "502", label:"学生" },
	{ value: "503", label:"政府" },
	{ value: "504", label:"军队" },
	{ value: "53", label:"物流运输" },
	{ value: "995", label:"其他" }
]

export const state_cd = [
	{ value: "", label:"请选择" },
	{ value: "北京", label:"北京" },
	{ value: "澳门", label:"澳门" },
	{ value: "天津", label:"天津" },
	{ value: "上海", label:"上海" },
	{ value: "重庆", label:"重庆" },
	{ value: "河北", label:"河北" },
	{ value: "山西", label:"山西" },
	{ value: "内蒙古", label:"内蒙古" },
	{ value: "辽宁", label:"辽宁" },
	{ value: "吉林", label:"吉林" },
	{ value: "黑龙江", label:"黑龙江" },
	{ value: "河南", label:"河南" },
	{ value: "新疆", label:"新疆" },
	{ value: "江苏", label:"江苏" },
	{ value: "浙江", label:"浙江" },
	{ value: "山东", label:"山东" },
	{ value: "安徽", label:"安徽" },
	{ value: "江西", label:"江西" },
	{ value: "湖北", label:"湖北" },
	{ value: "四川", label:"四川" },
	{ value: "云南", label:"云南" },
	{ value: "陕西", label:"陕西" },
	{ value: "甘肃", label:"甘肃" },
	{ value: "青海", label:"青海" },
	{ value: "宁夏", label:"宁夏" },
	{ value: "西藏", label:"西藏" },
	{ value: "广东", label:"广东" },
	{ value: "广西", label:"广西" },
	{ value: "福建", label:"福建" },
	{ value: "湖南", label:"湖南" },
	{ value: "海南", label:"海南" },
	{ value: "贵州", label:"贵州" },
	{ value: "香港", label:"香港" }
]

export const country_region = [
	{ value: "en_US", label: "USA - engelsk" },
	{ value: "es_ES", label: "Spanien - Spansk" },
  	{ value: "es_AR", label: "Argentina - Spansk" },
	{ value: "en_AW", label: "Aruba - Engelsk" },
	{ value: "en_AU", label: "Australien - Engelsk" },
	{ value: "de_AT", label: "Østrig - Tysk" },
	{ value: "en_BS", label: "Bahamas - Engelsk" },
	{ value: "en_BD", label: "Bangladesh - Engelsk" },
	{ value: "en_BB", label: "Barbados - Engelsk" },
	{ value: "nl_BE", label: "Belgien - Hollandsk" },
	{ value: "fr_BE", label: "Belgien - Fransk" },
	{ value: "es_BO", label: "Bolivia - Spansk" },
	{ value: "pt_BR", label: "Brasilien - Portugisisk" },
	{ value: "en_BG", label: "Bulgarien - Engelsk" },
	{ value: "en_CA", label: "Canada - Engelsk" },
	{ value: "fr_CA", label: "Canada - Fransk" },
	{ value: "es_CL", label: "Chile - Spansk" },
	{ value: "zh_CN", label: "Kina - Forenklet kinesisk" },
	{ value: "es_CO", label: "Colombia - Spansk" },
	{ value: "es_CR", label: "Costa Rica - Spansk" },
	{ value: "cs_CZ", label: "Tjekkiet - Tjekkisk" },
	{ value: "da_DK", label: "Danmark - Dansk" },
	{ value: "es_DO", label: "Dominikanske Republik - Spansk" },
	{ value: "es_EC", label: "Ecuador - Spansk" },
	{ value: "en_EG", label: "Egypten - Engelsk" },
	{ value: "es_SV", label: "El Salvador - Spansk" },
	{ value: "fi_FI", label: "Finland - Finsk" },
	{ value: "fr_FR", label: "Frankrig - Fransk" },
	{ value: "de_DE", label: "Tyskland - Tysk" },
	{ value: "el_GR", label: "Grækenland - Græsk" },
	{ value: "en_GD", label: "Grenada - Engelsk" },
	{ value: "es_GT", label: "Guatemala - Spansk" },
	{ value: "es_HN", label: "Honduras - Spansk" },
	{ value: "en_HK", label: "Hongkong - Engelsk" },
	{ value: "zh_HK", label: "Hongkong - Traditionelt kinesisk" },
	{ value: "hu_HU", label: "Ungarn - Ungarsk" },
	{ value: "en_IN", label: "Indien - Engelsk" },
	{ value: "en_ID", label: "Indonesien - Engelsk" },
	{ value: "in_ID", label: "Indonesien - Indonesisk" },
	{ value: "en_IE", label: "Irland - Engelsk" },
	{ value: "en_IL", label: "Israel - Engelsk" },
	{ value: "it_IT", label: "Italien - Italiensk" },
	{ value: "en_JM", label: "Jamaica - Engelsk" },
	{ value: "ja_JP", label: "Japan - Japansk" },
	{ value: "ko_KR", label: "Korea - Koreansk" },
	{ value: "en_KW", label: "Kuwait - Engelsk" },
	{ value: "en_LB", label: "Libanon - Engelsk" },
	{ value: "fr_LU", label: "Luxembourg - Fransk" },
	{ value: "en_MY", label: "Malaysia - Engelsk" },
	{ value: "es_MX", label: "Mexico - Spansk" },
	{ value: "en_MA", label: "Marokko - Engelsk" },
	{ value: "nl_NL", label: "Holland - Hollandsk" },
	{ value: "en_NZ", label: "New Zealand - Engelsk" },
	{ value: "es_NI", label: "Nicaragua - Spansk" },
	{ value: "no_NO", label: "Norge - Norsk" },
	{ value: "en_PK", label: "Pakistan - Engelsk" },
	{ value: "es_PA", label: "Panama - Spansk" },
	{ value: "es_PY", label: "Paraguay - Spansk" },
	{ value: "es_PE", label: "Peru - Spansk" },
	{ value: "en_PH", label: "Filippinerne - Engelsk" },
	{ value: "pl_PL", label: "Polen - Polsk" },
	{ value: "pt_PT", label: "Portugal - Portugisisk" },
	{ value: "es_PR", label: "Puerto Rico - Spansk" },
	{ value: "en_RO", label: "Rumænien - Engelsk" },
	{ value: "ru_RU", label: "Rusland - Russisk" },
	{ value: "en_SA", label: "Saudi - Arabien - Engelsk" },
	{ value: "en_SG", label: "Singapore - Engelsk" },
	{ value: "en_LK", label: "Sri Lanka - Engelsk" },
	{ value: "en_ZA", label: "Sydafrika - Engelsk" },
	{ value: "en_SI", label: "Slovenien - Engelsk" },
	{ value: "sv_SE", label: "Sverige - Svensk" },
	{ value: "fr_CH", label: "Schweiz - Fransk" },
	{ value: "de_CH", label: "Schweiz - Tysk" },
	{ value: "zh_TW", label: "Taiwan - Traditionelt kinesisk" },
	{ value: "en_TH", label: "Thailand - Engelsk" },
	{ value: "th_TH", label: "Thailand - Thai" },
	{ value: "en_TT", label: "Trinidad og Tobago - Engelsk" },
	{ value: "tr_TR", label: "Tyrkiet - Tyrkisk" },
	{ value: "en_AE", label: "Forenede Arabiske Emirater - Engelsk" },
	{ value: "en_GB", label: "Storbritannien - Engelsk" },
	{ value: "es_UY", label: "Uruguay - Spansk" },
	{ value: "es_VE", label: "Venezuela - Spansk" },
	{ value: "en_VN", label: "Vietnam - Engelsk" },
]

export const primaryUse = [
  { value: "", label: "Vælg mellem følgende" },
	{ value: "002", label: "Personlig brug" },
	{ value: "003", label: "Hjemmebaseret firma" },
	{ value: "005", label: "Virksomhed med 9 eller færre medarbejdere" },
	{ value: "006", label: "Virksomhed med 10 eller flere medarbejdere" },
]

export const salutation = [
  { value: "", label: "Vælg mellem følgende" },
	{ value: "001", label: "Hr." },
	{ value: "002", label: "Frk." },
	{ value: "005", label: "Fr." },
]

export const jobResponsibility = [
  { value: "", label: "Vælg en" },
	{ value: "041", label: "Administrativ assistent" },
	{ value: "030", label: "Akademiker" },
	{ value: "035", label: "Beskæftiget med CAD/CAM" },
	{ value: "033", label: "Virksomhedsejer" },
	{ value: "055", label: "Konsulent/ansvarlig for systemintegration" },
	{ value: "034", label: "Afdelingsleder" },
	{ value: "046", label: "Ingeniør/tekniker" },
	{ value: "054", label: "Bestyrelsesmedlem" },
	{ value: "036", label: "Chef for teknisk afdeling" },
	{ value: "047", label: "Beskæftiget i regnskabsafdelingen" },
	{ value: "031", label: "Økonomichef" },
	{ value: "037", label: "Grafiker" },
	{ value: "032", label: "It-chef (CIO/CTO)" },
	{ value: "051", label: "IT-strategiker" },
	{ value: "049", label: "Beskæftiget med markedsføring" },
	{ value: "045", label: "Gruppe- eller afdelingsleder for MIS/IT" },
	{ value: "044", label: "Beskæftiget med MIS/IT" },
	{ value: "042", label: "Office Manager" },
	{ value: "043", label: "Beskæftiget med indkøb og forsyning" },
	{ value: "050", label: "Beskæftiget med salg" },
	{ value: "040", label: "Beskæftiget inden for service/support" },
	{ value: "048", label: "Softwareudvikler" },
	{ value: "052", label: "Forstander/inspektør" },
	{ value: "053", label: "Lærer" },
	{ value: "000", label: "Andet" },
]

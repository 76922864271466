export const country_region = [
	{ value: "", label:"Select One" },
	{ value: "es_ES", label:"Spain - Spanish" },
	{ value: "en_US", label:"United States - English" },
    { value: "es_AR", label:"Argentina - Spanish" },
	{ value: "en_AW", label:"Aruba - English" },
	{ value: "en_AU", label:"Australia - English" },
	{ value: "de_AT", label:"Austria - German" },
	{ value: "en_BS", label:"Bahamas - English" },
	{ value: "en_BD", label:"Bangladesh - English" },
	{ value: "en_BB", label:"Barbados - English" },
	{ value: "nl_BE", label:"Belgium - Dutch" },
	{ value: "fr_BE", label:"Belgium - French" },
	{ value: "es_BO", label:"Bolivia - Spanish" },
	{ value: "pt_BR", label:"Brazil - Portuguese" },
	{ value: "en_BG", label:"Bulgaria - English" },
    { value: "en_CA", label:"Canada - English" },
	{ value: "fr_CA", label:"Canada - French" },
	{ value: "es_CL", label:"Chile - Spanish" },
	{ value: "zh_CN", label:"China - Simplified Chinese" },
	{ value: "es_CO", label:"Colombia - Spanish" },
	{ value: "es_CR", label:"Costa Rica - Spanish" },
	{ value: "cs_CZ", label:"Czech Republic - Czech" },
	{ value: "da_DK", label:"Denmark - Danish" },
	{ value: "es_DO", label:"Dominican Republic - Spanish " },
    { value: "es_EC", label:"Ecuador - Spanish" },
	{ value: "en_EG", label:"Egypt - English" },
	{ value: "es_SV", label:"El Salvador - Spanish" },
	{ value: "fi_FI", label:"Finland - Finnish" },
	{ value: "fr_FR", label:"France - French" },
	{ value: "de_DE", label:"Germany - German" },
	{ value: "el_GR", label:"Greece - Greek" },
	{ value: "en_GD", label:"Grenada - English" },
	{ value: "es_GT", label:"Guatemala - Spanish" },
    { value: "es_HN", label:"Honduras - Spanish" },
	{ value: "en_HK", label:"Hong Kong - English" },
	{ value: "zh_HK", label:"Hong Kong - Traditional Chinese" },
	{ value: "hu_HU", label:"Hungary - Hungarian" },
	{ value: "en_IN", label:"India - English" },
	{ value: "en_ID", label:"Indonesia - English" },
	{ value: "in_ID", label:"Indonesia - Indonesian" },
	{ value: "en_IE", label:"Ireland - English" },
	{ value: "en_IL", label:"Israel - English" },
	{ value: "it_IT", label:"Italy - Italian" },
    { value: "en_JM", label:"Jamaica - English" },
	{ value: "ja_JP", label:"Japan - Japanese" },
	{ value: "ko_KR", label:"Korea - Korean" },
	{ value: "en_KW", label:"Kuwait - English" },
	{ value: "en_LB", label:"Lebanon - English" },
	{ value: "fr_LU", label:"Luxembourg - French" },
    { value: "en_MY", label:"Malaysia - English" },
	{ value: "es_MX", label:"Mexico - Spanish" },
	{ value: "en_MA", label:"Morocco - English" },
	{ value: "nl_NL", label:"Netherlands - Dutch" },
	{ value: "en_NZ", label:"New Zealand - English" },
	{ value: "es_NI", label:"Nicaragua - Spanish" },
	{ value: "no_NO", label:"Norway - Norwegian" },
    { value: "en_PK", label:"Pakistan - English" },
	{ value: "es_PA", label:"Panama - Spanish" },
	{ value: "es_PY", label:"Paraguay - Spanish" },
	{ value: "es_PE", label:"Peru - Spanish" },
	{ value: "en_PH", label:"Philippines - English" },
	{ value: "pl_PL", label:"Poland - Polish" },
	{ value: "pt_PT", label:"Portugal - Portuguese" },
	{ value: "es_PR", label:"Puerto Rico - Spanish" },
    { value: "en_RO", label:"Romania - English" },
	{ value: "ru_RU", label:"Russia Federation - Russian" },
	{ value: "en_SA", label:"Saudi Arabia - English" },
	{ value: "en_SG", label:"Singapore - English" },
	{ value: "en_LK", label:"Sri Lanka - English" },
	{ value: "en_ZA", label:"South Africa - English" },
	{ value: "en_SI", label:"Slovenia - English" },
	{ value: "sv_SE", label:"Sweden - Swedish" },
	{ value: "fr_CH", label:"Switzerland - French" },
	{ value: "de_CH", label:"Switzerland - German" },
    { value: "zh_TW", label:"Taiwan - Traditional Chinese" },
	{ value: "en_TH", label:"Thailand - English" },
	{ value: "th_TH", label:"Thailand - Thai" },
	{ value: "en_TT", label:"Trinidad and Tobago - English " },
	{ value: "tr_TR", label:"Turkey - Turkish" },
    { value: "en_AE", label:"United Arab Emirates" },
	{ value: "en_GB", label:"United Kingdom - English" },
	{ value: "es_UY", label:"Uruguay - Spanish" },
	{ value: "es_VE", label:"Venezuela - Spanish" },
	{ value: "en_VN", label:"Vietnam - English" }
]

export const primaryUse = [
	{ value: "", label:"Select One" },
	{ value: "002", label:"Personal Use" },
	{ value: "003", label:"Home-based Business" },
	{ value: "005", label:"Business with 9 or fewer employees" },
	{ value: "006", label:"Business with 10 or more employees" },
]

export const salutation = [
	{ value: "", label:"Select One" },
	{ value: "001", label:"Mr." },
	{ value: "002", label:"Mrs." },
	{ value: "005", label:"Miss" },
]

export const jobResponsibility = [
    { value: "", label:"Select One" },
	{ value: "041", label:"Administrative Assistant" },
	{ value: "030", label:"Business Professional" },
	{ value: "035", label:"CAD/CAM Professional" },
	{ value: "033", label:"Company Owner/CEO" },
	{ value: "055", label:"Consultant/System Integrator" },
	{ value: "034", label:"Department Head" },
	{ value: "046", label:"Engineer/Technical" },
	{ value: "054", label:"Executive/Board Member" },
	{ value: "036", label:"Facilities Manager" },
	{ value: "047", label:"Finance/Accounting Professional" },
	{ value: "031", label:"Financial Officer/CFO" },
	{ value: "037", label:"Graphic Art Professional" },
	{ value: "032", label:"IT Executive (CIO/CTO)" },
	{ value: "051", label:"IT Strategist" },
	{ value: "049", label:"Marketing Professional" },
	{ value: "045", label:"MIS/IT Group/Dept. Manager" },
	{ value: "044", label:"MIS/IT Professional" },
	{ value: "042", label:"Office Manager" },
	{ value: "043", label:"Purchasing/Procurement Professional" },
	{ value: "050", label:"Sales Professional" },
	{ value: "040", label:"Service/Support/Help Desk" },
	{ value: "048", label:"Software Developer" },
	{ value: "052", label:"Superintendent/Headmaster" },
	{ value: "053", label:"Teacher" },
	{ value: "000", label:"Other" },
]

// libraies
import React from 'react';

// components

// services and hooks
import { geti18nText, geti18nLng, geti18nCC } from '../../assets/i18n/lang';

// config

// styles and resources
import './ConfirmationForm.css';

// consts and independent functions

/**
 * @category Views
 * @class
 * Main view
 *
 * @param  {Object} props
 * @return {View}
 */
const ConfirmationForm = (props) => {
  const {data, onReset} = props;

  return (  
    <div className="form-container">
      <div className="title-container"><h1>{geti18nText('title_confirm')}</h1></div>
      <div className="subtitle-container"><h3>{geti18nText('text_thanks_registration').replace('%USERNAME%', data.firstName)}</h3></div>
      <div className="block-container">
        <p>{geti18nText('text_sub_thanks_registration')}</p>
      </div>
      <div className="block-container block-lines">
        <div className="block-detail-line">
          <label>{`${geti18nText('field_product_name')}:`}</label>
          <div className="col-detail">{data.productName}</div>
        </div>
        <div className="block-detail-line">
          <label>{`${geti18nText('field_model_name')}:`}</label>
          <div className="col-detail">{data.productNumberName}</div>
        </div>
        <div className="block-detail-line">
          <label>{`${geti18nText('field_serial_number')}:`}</label>
          <div className="col-detail">{data.serialNumber}</div>
        </div>
        <div className="block-link"><span onClick={onReset}>{geti18nText('text_register_other')}</span></div>
      </div>
    </div>
  );
};

// class prop types

export default ConfirmationForm;
